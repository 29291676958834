<template>
  <div class="">
    <div v-show="isLoading" class="fullLoading">
      <loading :size="2" :icon="'spinner'" />
    </div>
    <div v-show="isLoaded">
      <ItemList :lists="items" :key="fullPath"/>
      <moreBtn :route="'/brand/' + brandCode + '/items/?page=2'">もっとみる</moreBtn>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {Mixin} from '@/mixins/common'
import ItemList from "@/components/block/Itemlist.vue";
import moreBtn from "@/components/parts/moreBtn.vue";
import loading from "@/components/atom/loading.vue";
import { mapActions } from "vuex"

export default {
  name: 'BrandTopItem',
  data() {
    return {
      brandCode: '',
      items: {},
      isLoading: true,
      isLoaded: false,
      fullPath: '',
      title: '',
      description: ''
    };
  },
  components: {
    ItemList,
    moreBtn,
    loading
  },
  mixins:[Mixin],
  created (){
    this.refresh()
    this.pageInit(this.$route)
    this.getCatItem()
  },
  beforeRouteUpdate(to, from, next) {
    // console.log('brand item list before route update');
    if(to.params.code != from.params.code){
      this.refresh()
      this.pageInit(to)
      this.getCatItem()
      window.scrollTo(0, 0);
    }
    next()
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
  methods: {
    ...mapActions([
      'setshareData'
    ]),
    refresh(){
      this.items = {}
      this.isLoading = true
      this.isLoaded = false
      this.fullPath = ''
      this.title = ''
      this.description = ''
    },
    pageInit(route){
      this.brandCode = route.params.code
    },
    async getCatItem(){
      const url = this.api + '/items/brands/' + this.brandCode + '/?'
      // console.log(url);

      await axios.get(url)
      .then((res) => {
        this.items = res.data.list
        this.fullPath = this.$route.fullPath
        this.isLoading = false
        this.isLoaded = true

        this.title = this.items[0].brand_name_full
        this.description = this.items[0].brand_name_full + 'のトップページ。新着アイテムやカテゴリから好みのアイテムを探したり、お近くの店舗を探すことができます。'

        // sharedata
        const shareData = {}
        shareData.title = this.title + ' | TOKYOWAVE'
        shareData.description = this.items[0].brand_name_full + 'のブランド情報ページ。'
        shareData.url = window.location.href
        this.setshareData(shareData)


        this.$emit('updateHead')
      })
      .catch((e) =>{
        // console.log(e);
        // console.log('item lists not found')
      })
    },
  }
}
</script>
